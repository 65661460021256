<template>
	<Transition name="fade" mode="out-in" :duration="500">
		<!-- ================= ANNOUNCEMENT ================= -->
		<div class="overlay" v-if="showAnnouncement && isHomePage && !isLogin" @click.self="onCloseAnnouncement">
			<div class="announcementDialog">
				<div class="announcementContent container noScrollBar">
					<!-- BANNER -->
					<div class="announcementImageContainer" v-if="languageFilteredAnnouncement[0].fileName">
						<div class="announcementImage">
							<BaseImage
								:imgSrc="languageFilteredAnnouncement[0].fileName"
								:isFullUrl="true"
								decoding="sync"
							/>
						</div>
					</div>
					<!-- BANNER -->

					<!-- ANNOUNCEMENT TEXT -->
					<div v-html="languageFilteredAnnouncement[0].content" class="announcementTextContent"></div>
					<!-- ANNOUNCEMENT TEXT -->

					<!-- CHECKBOX -->
					<div class="checkboxContainer">
						<InputCheckbox v-model="doNotShowAgain">
							<span class="checkboxLabel">{{ t("common.doNotShowAgain") }}</span>
						</InputCheckbox>
					</div>
					<!-- CHECKBOX -->

					<!-- CLOSE BUTTON -->
					<div class="announcementButtons">
						<button class="mainButton closeButton" @click="onCloseAnnouncement">
							{{ t("common.close") }}
						</button>
					</div>
					<!-- CLOSE BUTTON -->
				</div>
			</div>
		</div>
		<!-- ================= ANNOUNCEMENT ================= -->
	</Transition>

	<Transition name="fade" mode="out-in" :duration="500">
		<!-- ================= ANNOUNCEMENT POPUP ================= -->
		<div
			class="overlay"
			v-if="showAnnouncementPopUp && isHomePage && isLogin"
			:key="popUpIndex"
			@click.self="onCloseAnnouncementPopUp(popUpIndex)"
		>
			<div class="announcementDialog">
				<div class="announcementContent container noScrollBar">
					<!-- BANNER -->
					<div class="announcementImageContainer" v-if="sortedAnnouncementPopUpItem[popUpIndex].popUpImage">
						<div class="announcementImage">
							<BaseImage
								:imgSrc="
									getImageSrc(
										filteredAnnouncementPopUpItem[popUpIndex].popUpImage,
										filteredAnnouncementPopUpItem[popUpIndex].popUpType
									)
								"
								:isFullUrl="filteredAnnouncementPopUpItem[popUpIndex].popUpType == 2"
								decoding="sync"
								@click.self="
									() => {
										if (filteredAnnouncementPopUpItem[popUpIndex].popUpType == 1)
											handleClaimBonus(filteredAnnouncementPopUpItem[popUpIndex].actionId);
										if (filteredAnnouncementPopUpItem[popUpIndex].popUpType == 3) {
											onCloseAnnouncementPopUp(popUpIndex);
											redirectCheckIn();
										}
									}
								"
							/>
						</div>

						<div v-if="filteredAnnouncementPopUpItem[popUpIndex].popUpType == 1" class="insetButtons">
							<button
								class="doneButton secondaryButton"
								@click.stop="onCloseAnnouncementPopUp(popUpIndex)"
							>
								{{ t("common.close") }}
							</button>
							<button
								class="claimNowButton mainButton"
								v-if="hasActiveBonus"
								@click.stop="hasActiveBonusClick"
							>
								{{ t("account.requirementNotMet") }}
							</button>
							<button
								class="claimNowButton mainButton"
								v-else-if="
									filteredAnnouncementPopUpItem[popUpIndex]?.extra?.toUpperCase() == 'NEW MEMBER'
								"
								@click.stop="handleClaimBonus(filteredAnnouncementPopUpItem[popUpIndex].actionId)"
							>
								{{ t("common.claimNow") }}
							</button>
							<button
								class="claimNowButton mainButton"
								v-else-if="
									filteredAnnouncementPopUpItem[popUpIndex]?.extra?.toUpperCase() == 'REFERRAL'
								"
								@click.stop="
									onCloseAnnouncementPopUp(popUpIndex);
									redirectReferral();
								"
							>
								{{ t("common.referralNow") }}
							</button>
						</div>

						<div v-if="filteredAnnouncementPopUpItem[popUpIndex].popUpType == 3" class="insetButtons">
							<button
								class="doneButton secondaryButton"
								@click.stop="onCloseAnnouncementPopUp(popUpIndex)"
							>
								{{ t("common.close") }}
							</button>
							<button
								class="claimNowButton mainButton"
								@click.stop="
									() => {
										onCloseAnnouncementPopUp(popUpIndex);
										redirectCheckIn();
									}
								"
							>
								{{ t("common.checkIn") }}
							</button>
						</div>
					</div>
					<!-- BANNER -->

					<template v-if="filteredAnnouncementPopUpItem[popUpIndex].popUpType == 2">
						<!-- ANNOUNCEMENT TEXT -->
						<div
							class="announcementTitle"
							v-if="filteredAnnouncementPopUpItem[popUpIndex].title"
							v-html="filteredAnnouncementPopUpItem[popUpIndex].title"
						></div>
						<div
							v-if="filteredAnnouncementPopUpItem[popUpIndex].content"
							v-html="filteredAnnouncementPopUpItem[popUpIndex].content"
							class="announcementTextContent"
						></div>
						<!-- ANNOUNCEMENT TEXT -->

						<!-- CHECKBOX -->
						<div class="checkboxContainer">
							<InputCheckbox v-model="doNotShowAgain">
								<span class="checkboxLabel">{{ t("common.doNotShowAgain") }}</span>
							</InputCheckbox>
						</div>
						<!-- CHECKBOX -->

						<!-- CLOSE BUTTON -->
						<div class="announcementButtons">
							<button class="mainButton closeButton" @click="onCloseAnnouncementPopUp">
								{{ t("common.close") }}
							</button>
						</div>
						<!-- CLOSE BUTTON -->
					</template>
				</div>
			</div>
		</div>
		<!-- ================= ANNOUNCEMENT POPUP ================= -->
	</Transition>
</template>

<script setup>
import { ref, computed, watch, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import { toastOptionSuccess, toastOptionError, toastOptionInfo } from "@/composables/useToastOptions";
import { useLoading } from "vue-loading-overlay";
import { useCookies } from "vue3-cookies";
import { isFeatureEnabled, routeData } from "@/constant";
import { isLogin } from "@/composables/useAuth";
import { languageCodeToId } from "@/composables/useCulture";
import BaseImage from "@/components/dynamic/BaseImage.vue";
import UnifiedLink from "@/components/dynamic/UnifiedLink.vue";
import InputCheckbox from "@/components/dynamic/Inputs/InputCheckbox.vue";

const store = useStore();
const { t, locale } = useI18n();
const { cookies } = useCookies();
const router = useRouter();
const route = useRoute();
const toast = useToast();
const $loading = useLoading();

const checkIn = routeData.find((route) => route.title == "checkin");
const statement = routeData.find((item) => item.title == "statement");
const referral = routeData.find((item) => item.title == "referral");
const transfer = routeData.find((item) => item.title == "transfer");

const isHomePage = computed(() => route.path == "/");

// ================= Announcement Start =================

const announcementItem = computed(() => store.getters["announcement/getAnnouncement"]);
const languageFilteredAnnouncement = computed(() =>
	announcementItem.value.filter((announcement) => announcement.langId == languageCodeToId[locale.value])
);
const hideAnnouncement = ref(false);
const showAnnouncement = ref(false);

// ================= Announcement End =================

// ================= Announcement Popup Start =================

const announcementPopUpItem = computed(() => store.getters["announcement/getAnnouncementPopUp"]);
const sortedAnnouncementPopUpItem = computed(() =>
	announcementPopUpItem.value.toSorted((a, b) => {
		return a.sequence - b.sequence;
	})
);
const filteredAnnouncementPopUpItem = computed(() => {
	let res = sortedAnnouncementPopUpItem.value.filter((item) => !hidePopUpTypes.value[item.popUpType]);
	if (!isFeatureEnabled["REGISTER"]) res = res.filter((item) => item?.extra?.toUpperCase() != "NEW MEMBER");
	if (!isFeatureEnabled["REFERRAL_CLAIM"]) res = res.filter((item) => item?.extra?.toUpperCase() != "REFERRAL");
	return res;
});

const popUpIndex = ref(0);
const hidePopUpTypes = ref(cookies.get("hidePopUpTypes") || {});
const showAnnouncementPopUp = computed(
	() => filteredAnnouncementPopUpItem.value.length && popUpIndex.value < filteredAnnouncementPopUpItem.value.length
);

const getImageSrc = (imgSrc, type) => {
	if (type == 1 && process.env.VUE_APP_ENV == "Staging") return "moon99/Bonus/BannerImage/" + imgSrc;
	if (type == 1) return "Bonus/BannerImage/" + imgSrc;
	if (type == 2) return imgSrc;
	if (type == 3 && process.env.VUE_APP_ENV == "Staging") return "moon99/Event/PopUpImage/" + imgSrc;
	if (type == 3) return "Event/PopUpImage/" + imgSrc;
};

const isLoginEventStart = computed(() => isLogin.value && store.getters["events/getMemberLoginEvent"]);
const redirectCheckIn = () => {
	if (isLoginEventStart.value) router.push(checkIn.link);
	else toast.info(t("toast.checkInNotStartYet"), toastOptionInfo);
};

const redirectReferral = () => {
	router.push(referral.link);
};

// ================= Announcement Popup End =================

// ================= Open/Close Start =================

const doNotShowAgain = ref(false);

const initAnnouncement = () => {
	hideAnnouncement.value = cookies.get("hideAnnouncement") == "true" || false;
	if (
		languageFilteredAnnouncement.value.length &&
		languageFilteredAnnouncement.value[0].status &&
		!hideAnnouncement.value
	)
		showAnnouncement.value = true;
};
const onCloseAnnouncement = () => {
	let midnight = new Date();
	midnight.setHours(23, 59, 59, 0);
	cookies.set("hideAnnouncement", doNotShowAgain.value, midnight);
	doNotShowAgain.value = false;
	showAnnouncement.value = false;
};

const initPopUp = () => {
	hidePopUpTypes.value = cookies.get("hidePopUpTypes") || {};
	popUpIndex.value = 0;
};
const onCloseAnnouncementPopUp = () => {
	let midnight = new Date();
	midnight.setHours(23, 59, 59, 0);

	const popUpType = filteredAnnouncementPopUpItem.value[popUpIndex.value].popUpType;
	let newCookies = cookies.get("hidePopUpTypes");
	newCookies = { ...newCookies };
	newCookies[popUpType] = doNotShowAgain.value;
	cookies.set("hidePopUpTypes", newCookies, midnight);

	doNotShowAgain.value = false;
	popUpIndex.value += 1;
};

// ================= Open/Close End =================

// ================= Fetch Start =================

const fetchAnnouncement = async () => {
	if (isLogin.value) {
		await store.dispatch("announcement/fetchAnnouncementPopUp", { langId: languageCodeToId[locale.value] });
		initPopUp();
	} else {
		await store.dispatch("announcement/fetchAnnouncement", "BeforeLogin");
		initAnnouncement();
	}
};

const refreshStatus = computed(() => {
	return store.getters["identityServer/getOnRefresh"];
});
watch(refreshStatus, (isRefresh) => {
	if (!isRefresh) fetchAnnouncement();
});
watch(isLogin, (newLoginStatus) => {
	fetchAnnouncement();
});

// ================= Fetch End =================

// ================= Active Bonus Start =================

const getBonusApplied = computed(() => store.getters["bonus/getActiveBonusAppliedWithBonusVendor"]);
const hasActiveBonus = computed(() => getBonusApplied.value?.length > 0);

// ================= Active Bonus End =================

// ================= Submit Start =================

const handleClaimBonus = (actionId) => {
	onCloseAnnouncementPopUp();
	router.push({
		path: transfer.link,
		query: {
			bonusId: actionId,
		},
	});
};

const hasActiveBonusClick = () => {
	if (hasActiveBonus.value) {
		toast.error(t("toast.bonusOngoing"), {
			onClick: () => {
				router.push({
					path: statement.link,
					query: {
						type: "bonus",
					},
				});
			},
		});
	}
};

// const handleClaimBonus = async (actionId) => {
// 	const loader = $loading.show();

// 	try {
// 		// const claimBonusRes = await store.dispatch("bonus/claimBonus", { bonusID: actionId });
// 		// if (!claimBonusRes?.succeeded || !claimBonusRes?.bonusId) {
// 		// 	toast.error(t("toast.claimUnsuccessful", { error: claimBonusRes?.error }), toastOptionError);
// 		// 	return false;
// 		// }
// 		// const claimBonusIdRes = await store.dispatch("bonus/claimBonusId", {
// 		// 	bonusClaimId: claimBonusRes?.bonusId,
// 		// 	bonusId: actionId,
// 		// });

// 		const directClaimRes = await store.dispatch("bonus/directClaim", { bonusId: actionId });
// 		if (!directClaimRes?.succeeded || !directClaimRes?.bonusId) {
// 			toast.error(t("toast.claimUnsuccessful", { error: directClaimRes?.error }), toastOptionError);
// 			return false;
// 		} else {
// 			toast.success(t("toast.claimSuccessful"), toastOptionSuccess);
// 			emitBonusClaimed();
// 			await store.dispatch("member/fetchMember");
// 			// await store.dispatch("announcement/fetchAnnouncementPopUp", { langId: languageCodeToId[locale.value] });
// 		}
// 	} catch (err) {
// 		toast.error(t("toast.claimUnsuccessful", { error: err.response?.data.title }), toastOptionError);
// 	} finally {
// 		loader.hide();
// 		onCloseAnnouncementPopUp();
// 	}
// };

// const emitBonusClaimed = () => {
// 	// broadcast signal for other tabs to close announcement
// 	localStorage.setItem("bonusClaimed", Date.now().toString());
// };
// const watchBonusClaimed = () => {
// 	window.addEventListener("storage", (event) => {
// 		// close bonus announcement if claimed on another tab
// 		if (event.key === "bonusClaimed" && filteredAnnouncementPopUpItem.value[popUpIndex.value].popUpType == 1) {
// 			onCloseAnnouncementPopUp();
// 		}
// 	});
// };

// ================= Submit End =================

// onMounted(() => {
// 	watchBonusClaimed();
// });
</script>

<style scoped lang="sass">
.overlay
	z-index: 1000
	overflow: auto
	display: flex
.announcementDialog
	position: relative
	display: contents
	width: auto
	height: 100%
	pointer-events: none
.announcementContent
	display: flex
	flex-direction: column
	justify-content: center
	gap: 1rem
	width: fit-content
	height: fit-content
	max-height: 100%
	padding: 1rem
	margin-block: auto
	pointer-events: auto
	z-index: 1001
.announcementImageContainer
	position: relative
	display: flex
	margin: 0 auto
	overflow: hidden
.announcementImage
	display: flex
	justify-content: center
.announcementImage img
	max-width: 100%
	max-height: 100%
	width: min-content
	object-fit: contain
	border-radius: 10px
.insetButtons
	position: absolute
	bottom: 3rem
	padding-inline: 0.5rem
	display: flex
	justify-content: center
	gap: 1rem
	width: 100%
	@media (max-width: 767px)
		bottom: 1rem
.claimNowButton, .doneButton
	width: 100%
	max-width: 13rem
	padding: 0.7rem
	box-shadow: 0 0 6px 0 #00000033
	line-height: normal
.doneButton
	outline: none
	border: none
.announcementTitle
	text-align: center
	font-weight: var(--font-medium)
	:deep(p)
		margin: 0
.announcementTextContent
	text-align: center
.checkboxContainer
	display: flex
	justify-content: center
.announcementButtons
	display: flex
	justify-content: center
	width: fit-content
	margin: 0 auto
.closeButton
	margin-inline: auto
	min-width: 15rem
</style>
